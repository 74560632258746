<ngb-carousel *ngIf="logos" [showNavigationArrows]="showNavigationArrows" [showNavigationIndicators]="showNavigationIndicators">
    <ng-template ngbSlide *ngFor="let image of logos">
        <div class="picsum-img-wrapper">
            <a [href]="url" target="_blank">
                <img style="width: 100%;" [src]="image" alt="Random slide">
            </a>
        </div>
    </ng-template>
</ngb-carousel>

<div class="container my-2">
    <div class="row text-center">
        <div class="col-md-3 mx-auto" *ngFor="let producto of productos">
            <a [href]="url" target="_blank">
                <img [src]="producto" style="width: 100%;" alt="">
            </a>
        </div>
    </div>
</div>