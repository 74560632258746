<div class="card" style="width: 100%; min-height: 100vh;">
    <div class="card-body">
        <h5 class="card-title text-center">Conferencias</h5>
        <ul class="nav nav-tabs mx-auto">
            <li (click)="cambiarTabs(1)" class="nav-item">
                <a class="nav-link" [class.active]="activo==1" href="javascript:void(0);">Presencial</a>
            </li>
            <li (click)="cambiarTabs(3)" class="nav-item">
                <a class="nav-link" [class.active]="activo==3" href="javascript:void(0);">Online</a>
            </li>
            <li (click)="cambiarTabs(4)" class="nav-item">
                <a class="nav-link" [class.active]="activo==4" href="javascript:void(0);">Paneles</a>
            </li>
            <li (click)="cambiarTabs(2)" class="nav-item">
                <a class="nav-link" [class.active]="activo==2" href="javascript:void(0);">Programa</a>
            </li>
        </ul>
        <div *ngIf="activo==1" class="container text-center">
            <div class="row">
                <div class="col-md-6" *ngFor="let ponente of presencial">
                    <div class="card my-2" style="width: 100%;">
                        <a [routerLink]="['/detallePonencia/'+ponente.id]">
                            <img [src]="ponente.urlImg" class="card-img-top" [alt]="ponente.nombrePonente">
                        </a>
                        <div class="card-body">

                        </div>
                        <div class="card-footer">
                            CONFERENCIA
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="activo==3" class="container text-center">
            <div class="row">
                <div class="col-md-6" *ngFor="let ponente of online">
                    <div class="card my-2" style="width: 100%;">
                        <a [routerLink]="['/detallePonencia/'+ponente.id]">
                            <img [src]="ponente.urlImg" class="card-img-top" [alt]="ponente.nombrePonente">
                        </a>
                        <div class="card-body">

                        </div>
                        <div class="card-footer">
                            CONFERENCIA
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="activo==4" class="container text-center">
            <div class="row">
                <div class="col-md-6" *ngFor="let ponente of paneles">
                    <div class="card my-2" style="width: 100%;">
                        <a [routerLink]="['/detallePonencia/'+ponente.id]">
                            <img [src]="ponente.urlImg" class="card-img-top" [alt]="ponente.nombrePonente">
                        </a>
                        <div class="card-body">

                        </div>
                        <div class="card-footer">
                            PANEL
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="activo==2" class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="card mb-3" style="width: 100%;">
                        <div class="row no-gutters">
                            <div class="col-md-12">
                                <div class="card-body">
                                    <hr>
                                    <hr>
                                    <h2 class="text-center">Programa</h2>
                                    <hr>
                                    <hr>
                                    <div class="col-12">
                                        <img class="my-1" src="https://firebasestorage.googleapis.com/v0/b/nets-web.appspot.com/o/WEF2024%2FPrograma_Vertical_Online-1.png?alt=media&token=5b529be3-abef-418f-8c09-7308ed2dea1b" style="width: 100%;" alt="Programa WEF">
                                    </div>
                                    <div class="col-12">
                                        <img class="my-1" src="https://firebasestorage.googleapis.com/v0/b/nets-web.appspot.com/o/WEF2024%2FPrograma_Vertical_Online-2.png?alt=media&token=1b938baa-cdc2-4cab-9508-e3bbfb5788dd" style="width: 100%;" alt="Programa WEF">
                                    </div>
                                    <hr>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
