import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from '@app/app-routing.module';
import {AppComponent} from '@app/app.component';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {HomeComponent} from '@app/components/home/home.component';
import {HttpClientModule} from '@angular/common/http';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ToastrModule} from 'ngx-toastr';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { Ng5SliderModule } from 'ng5-slider';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import { ChartsModule } from 'ng2-charts';
import { MatMenuModule} from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDialogModule} from '@angular/material/dialog';

import { ClpPipe } from './clp.pipe';
import { EntradaComponent } from './components/entrada/entrada.component';
import { LobbyComponent } from './components/lobby/lobby.component';
import { AuditorioComponent } from './components/auditorio/auditorio.component';
import { ListPonenciasComponent } from './components/list-ponencias/list-ponencias.component';
import { DetallePonenciaComponent } from './components/detalle-ponencia/detalle-ponencia.component';
import { DetalleStandComponent } from './components/detalle-stand/detalle-stand.component';
import { ListStandsComponent } from './components/list-stands/list-stands.component';
import { FeriaComponent } from './components/feria/feria.component';
import { NuevaPonenciaComponent } from './components/nueva-ponencia/nueva-ponencia.component';
import { NuevoStandComponent } from './components/nuevo-stand/nuevo-stand.component';
import { HomeAdminComponent } from './components/home-admin/home-admin.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import {IvyCarouselModule} from 'angular-responsive-carousel';
import { Sala1Component } from './components/sala1/sala1.component';
import { Sala2Component } from './components/sala2/sala2.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ClpPipe,
    EntradaComponent,
    LobbyComponent,
    AuditorioComponent,
    ListPonenciasComponent,
    DetallePonenciaComponent,
    DetalleStandComponent,
    ListStandsComponent,
    FeriaComponent,
    NuevaPonenciaComponent,
    NuevoStandComponent,
    HomeAdminComponent,
    Sala1Component,
    Sala2Component
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatTableModule,
    Ng5SliderModule,
    AngularFireModule.initializeApp(environment.firebase),
    ChartsModule,
    CKEditorModule,
    IvyCarouselModule,
    MatMenuModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,MatExpansionModule,    
    MatDialogModule, NgbModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
