export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAYXRjAMZIJpSDLFs2ForTApuv8HnPz9Q8",
    authDomain: "nets-web.firebaseapp.com",
    projectId: "nets-web",
    storageBucket: "nets-web.appspot.com",
    messagingSenderId: "359644585190",
    appId: "1:359644585190:web:6f271340fc6e4bb990af27",
    measurementId: "G-EJHG9N8K9G"
  }
};
