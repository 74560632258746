<div class="card" style="width: 100%; min-height: 100vh;">
    <div class="card-body">
        <ul class="nav nav-tabs mx-auto">
            <li (click)="cambiarTabs(1)" class="nav-item">
                <a class="nav-link" [class.active]="activo==1" href="javascript:void(0);">Stands</a>
            </li>
            <li (click)="cambiarTabs(2)" class="nav-item">
                <a class="nav-link" [class.active]="activo==2" href="javascript:void(0);">Conferencias</a>
            </li>
            <li (click)="cambiarTabs(3)" class="nav-item">
                <a class="nav-link" [class.active]="activo==3" href="javascript:void(0);">Programa</a>
            </li>
        </ul>
        <div *ngIf="activo==1" class="card" style="width: 100%;">
            <div class="card-body">
                <div class="container">
                    <div class="row">
                        <div class="col-8">
                            <h2>Stands</h2>
                        </div>
                        <div class="col-4">
                            <a [routerLink]="['/stand/new']" class="btn btn-primary mx-2"><i class="icon-plus"
                                    aria-hidden="true"></i></a>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-md-3" *ngFor="let stand of stands">
                            <div class="card" style="width: 100%;">
                                <div class="card-body">
                                    <h5 class="card-title">{{stand.nombreStand}}</h5>
                                    <p class="card-text">{{stand.slogan}}</p>
                                </div>
                                <div class="card-action text-center">
                                    <a [routerLink]="['/stand/'+stand.id]" class="btn btn-primary mx-2"><i
                                            class="icon-pencil" aria-hidden="true"></i></a>
                                    <button (click)="eliminarStand(stand.id)" class="btn btn-primary mx-2"><i
                                            class="icon-trash" aria-hidden="true"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="activo==2" class="card" style="width: 100%;">
            <div class="card-body">
                <div class="container">
                    <div class="row">
                        <div class="col-8">
                            <h2>Conferencias</h2>
                        </div>
                        <div class="col-4">
                            <a [routerLink]="['/ponencia/new']" class="btn btn-primary mx-2"><i class="icon-plus"
                                    aria-hidden="true"></i></a>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-md-3" *ngFor="let ponencia of ponencias">
                            <div class="card" style="width: 100%;">
                                <div class="card-body">
                                    <h5 class="card-title">{{ponencia.nombrePonente}}</h5>
                                    <p class="card-text">{{ponencia.nombrePonencia}}</p>
                                </div>
                                <div class="card-action text-center">
                                    <a [routerLink]="['/ponencia/'+ponencia.id]" class="btn btn-primary mx-2"><i
                                            class="icon-pencil" aria-hidden="true"></i></a>
                                    <button (click)="eliminarPonencia(ponencia.id)" class="btn btn-primary mx-2"><i
                                            class="icon-trash" aria-hidden="true"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="activo==3" class="card" style="width: 100%;">
            <ckeditor [editor]="Editor" id="descripcionPonente" name='ponencia' [config]="{ toolbar: [ 'bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote','Heading', 'Link', 'insertTable'] }" [(ngModel)]="cronograma"></ckeditor>
            <button [disabled]="guardando" class="btn btn-block btn-primary my-2" (click)="guardar()">Guardar</button>
        </div>
    </div>
</div>