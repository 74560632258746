import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import ImageMap from "image-map";
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})

export class HomeComponent implements OnInit {

  @ViewChild('content', {static: true}) content: ElementRef;

  constructor(config: NgbModalConfig, private modalService: NgbModal, private title: Title) {
    // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;
    title.setTitle('WEF CHILE 2024 - Entrada')
  }

  open() {
    this.modalService.open(this.content, { size: 'xl' });
  }

  ngOnInit() {
    ImageMap('img[usemap]');
    this.modalService.open(this.content, { size: 'xl', centered: true  });
  }

}
