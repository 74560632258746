<div class="card" style="width: 100%;">
    <div class="card-body">

        <form>
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Nombre de la Empresa</label>
                            <input type="text" class="form-control" name="nombreEmpresa" id="exampleInputEmail1" [(ngModel)]="nombre" aria-describedby="emailHelp">
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label for="exampleInputEmail2">Slogan de la Empresa</label>
                            <input type="text" class="form-control" name="sloganEmpresa" id="exampleInputEmail2" [(ngModel)]="slogan" aria-describedby="emailHelp">
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail2">URL de redireccion</label>
                            <input type="text" class="form-control" name="urlEmpresa" id="exampleInputEmail2" [(ngModel)]="url" aria-describedby="emailHelp">
                        </div>
                        <div class="form-group">
                            <label for="exampleFormControlSelect1">Categoría</label>
                            <select name="category" class="form-control" id="exampleFormControlSelect1" [(ngModel)]="cat">
                                <option value="1">Gastronomía</option>
                                <option value="2">Tecnología</option>
                                <option value="3">Librería</option>
                                <option value="4">Artesanía</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="exampleFormControlFile1">Foto del Logo</label>
                            <input type="file" id="file" #userPhoto (change)="onFileSelected($event)" accept=".png,.jpg" name="image" autocomplete="off" />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <img style="max-width: 100%;" [src]="urlLogo" alt="">
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="exampleFormControlFile1">Foto del Stand</label>
                            <input type="file" id="file1" #userPhoto1 (change)="onFileSelected2($event)" multiple max="3" maxlength="3" accept=".png,.jpg" name="image1" autocomplete="off" />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <img style="max-width: 100%;" [src]="urlStand" alt="">
                    </div>
                    <div class="col-12">
                        <div class="card text-center">
                            <div class="card-body">
                                <h5 class="card-title">Agregar imágenes de los productos.</h5>
                                <div class="form-group col-12">
                                    <input type="file" id="file" #userPhotos (change)="onFileSelected1($event)" multiple max="4" maxlength="4" accept=".png,.jpg" name="image" autocomplete="off" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="card text-center">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-3" *ngFor="let im of imagesP;index as i">
                                        <div *ngIf="im!==''" class="product-img">
                                            <img [src]="im" alt="" width="70%">
                                        </div>
                                        <button *ngIf="im!==''" class="delete" style="border-radius: 50%;" (click)="deleteImg(i)"><i class="icon-close"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button class="btn btn-block btn-primary" (click)="guardarStand()">Guardar</button>
        </form>
    </div>
</div>