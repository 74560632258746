import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ProductService } from '@app/services/product.service';
import { Observable } from 'rxjs';
import { map, finalize } from "rxjs/operators";

@Component({
  selector: 'app-detalle-ponencia',
  templateUrl: './detalle-ponencia.component.html',
  styleUrls: ['./detalle-ponencia.component.scss']
})
export class DetallePonenciaComponent implements OnInit {

  public data: string;
  public urlImg: string;
  public nombre: string;
  public nombrePonencia: string;
  public contPonencia: string;
  public editar: boolean;
  public id: string;

  constructor(private dbf: ProductService, private router: Router,
    private route: ActivatedRoute, private title: Title) {
  }

  ngOnInit() {
    this.route.paramMap
      .pipe(
        map((param: ParamMap) => {
          // @ts-ignore
          return { id: param.params.id };
        })
      ).subscribe(dataPar => {
        console.log('id', dataPar);
        this.id = dataPar.id;
        this.dbf.obtenerPonencia(dataPar.id).then(dataP => {
          console.log(dataP.data());
          this.data = dataP.data().bio;
          this.nombre = dataP.data().nombrePonente;
          this.nombrePonencia = dataP.data().nombrePonencia;
          this.contPonencia = dataP.data().contenido;
          this.urlImg = dataP.data().urlImg;
          this.title.setTitle('WEF CHILE 2024 - '+ this.nombre);
        });
      });
  }

}
