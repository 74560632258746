import { Component, OnInit } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Observable } from 'rxjs';
import { map, finalize } from "rxjs/operators";
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ProductService } from '@app/services/product.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-nueva-ponencia',
  templateUrl: './nueva-ponencia.component.html',
  styleUrls: ['./nueva-ponencia.component.scss']
})
export class NuevaPonenciaComponent implements OnInit {

  public Editor = ClassicEditor;
  public data: string;
  public urlImg: string;
  public nombre: string;
  public nombrePonencia: string;
  public contPonencia: string;
  public editar: boolean;
  public id: string;
  public infoPonencia: string;
  public guardando = false;
  selectedFile: File = null;
  fb;
  downloadURL: Observable<string>;


  constructor(private dbf: ProductService, private storage: AngularFireStorage, private router: Router,
    private route: ActivatedRoute, private toastr: ToastrService) {
    this.data = '';
    this.nombre = '';
    this.nombrePonencia = '';
    this.contPonencia = '';
    this.infoPonencia = '';
    this.urlImg = this.urlImg ? this.urlImg : 'https://image.freepik.com/vector-gratis/perfil-avatar-hombre-icono-redondo_24640-14044.jpg'
  }

  ngOnInit() {
    this.route.paramMap
      .pipe(
        map((param: ParamMap) => {
          // @ts-ignore
          return { id: param.params.id };
        })
      ).subscribe(dataPar => {
        console.log('id', dataPar);
        this.id = dataPar.id;
        if (dataPar.id !== 'new') {
          this.editar = true;
          this.dbf.obtenerPonencia(dataPar.id).then(dataP => {
            console.log(dataP.data());
            this.data = dataP.data().bio;
            this.infoPonencia = dataP.data().infoPonencia;
            this.nombre = dataP.data().nombrePonente;
            this.nombrePonencia = dataP.data().nombrePonencia;
            this.contPonencia = dataP.data().contenido;
            this.urlImg = dataP.data().urlImg;
          });
        } else {
          this.editar = false;
        }
      });
  }

  guardarPonencia() {
    this.guardando = true;
    console.log(this.editar);
    if (this.editar) {
      this.dbf.editarPonencia(this.id, { nombrePonente: this.nombre, infoPonencia: this.infoPonencia, bio: this.data, nombrePonencia: this.nombrePonencia, contenido: this.contPonencia, urlImg: this.urlImg }).then(data => {
        console.log('Editado', data);
        this.toastr.success('La conferencia de ' + this.nombre + ' se edito', 'Editado');
        this.guardando = false;
        this.router.navigateByUrl('/admin');
      });
    } else {
      this.dbf.crearPonencia({ nombrePonente: this.nombre, infoPonencia: this.infoPonencia, bio: this.data, nombrePonencia: this.nombrePonencia, contenido: this.contPonencia, urlImg: this.urlImg }).then(data => {
        console.log('Creado', data);
        this.toastr.success('La conferencia de ' + this.nombre + ' se creo', 'Creado');
        this.guardando = false;
        this.router.navigateByUrl('/admin');
      });
    }
  }


  async onFileSelected(event) {
    var n = Date.now();
    const file = event.target.files[0];
    const filePath = `Ponencias/${n}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(`Ponencias/${n}`, file);
    await task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.downloadURL = fileRef.getDownloadURL();
          this.downloadURL.subscribe(url => {
            if (url) {
              this.fb = url;
            }
            this.urlImg = url;
            console.log("URL", this.urlImg);
          });
        })
      )
      .subscribe(url => {
        if (url) {
          console.log('ESTE', url);
        }
      });
  }


}
