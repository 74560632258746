import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import ImageMap from "image-map";
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-lobby',
  templateUrl: './lobby.component.html',
  styleUrls: ['./lobby.component.scss']
})
export class LobbyComponent implements OnInit {
  constructor(private title: Title) {
    title.setTitle('WEF CHILE 2024 - Lobby')
  }

  ngOnInit() {
    ImageMap('img[usemap]');
  }

}
