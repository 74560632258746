import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { AuditorioComponent } from './components/auditorio/auditorio.component';
import { DetallePonenciaComponent } from './components/detalle-ponencia/detalle-ponencia.component';
import { DetalleStandComponent } from './components/detalle-stand/detalle-stand.component';
import { EntradaComponent } from './components/entrada/entrada.component';
import { FeriaComponent } from './components/feria/feria.component';
import { HomeAdminComponent } from './components/home-admin/home-admin.component';
import {HomeComponent} from './components/home/home.component';
import { ListPonenciasComponent } from './components/list-ponencias/list-ponencias.component';
import { ListStandsComponent } from './components/list-stands/list-stands.component';
import { LobbyComponent } from './components/lobby/lobby.component';
import { NuevaPonenciaComponent } from './components/nueva-ponencia/nueva-ponencia.component';
import { NuevoStandComponent } from './components/nuevo-stand/nuevo-stand.component';
import { Sala1Component } from './components/sala1/sala1.component';
import { Sala2Component } from './components/sala2/sala2.component';
import { AdminGuard } from './guard/admin.guard';

const routes: Routes = [
  // Define routes for the landing / home page, create a separate component for the layout of home page
  // put only header, footer and router-outlet there
  {
    path: '', component: HomeComponent
  },
  {
    path: 'entrada', component: EntradaComponent
  },
  {
    path: 'lobby', component: LobbyComponent
  },
  {
    path: 'auditorio', component: AuditorioComponent
  },
  {
    path: 'conferencias', component: ListPonenciasComponent
  },
  /*{
    path: 'stands', component: ListStandsComponent
  },
  {
    path: 'feria', component: FeriaComponent
  },*/
  {
    path: 'admin', component: HomeAdminComponent
  },
  {
    path: 'ponencia/:id', component: NuevaPonenciaComponent
  },
  /*{
    path: 'stand/:id', component: NuevoStandComponent
  },*/
  {
    path: 'detallePonencia/:id', component: DetallePonenciaComponent
  },
  /*{
    path: 'detalleStand/:id', component: DetalleStandComponent
  },*/
  {
    path: 'sala1', component: Sala1Component
  },/*
  {
    path: 'sala2', component: Sala2Component
  },*/
  // Wildcard Route if no route is found == 404 NOTFOUND page
  {
    path: '**', pathMatch: 'full', redirectTo: ''
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
