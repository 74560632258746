import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import ImageMap from "image-map";

@Component({
  selector: 'app-entrada',
  templateUrl: './entrada.component.html',
  styleUrls: ['./entrada.component.scss']
})
export class EntradaComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
    ImageMap('img[usemap]');
  }

}
