<div class="card" style="width: 100%;">
    <div class="card-body">

        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <form>
                        <div class="form-group">
                            <label for="exampleFormControlFile1">Foto del Ponente</label>
                            <input type="file" id="file" #userPhoto (change)="onFileSelected($event)" accept=".png,.jpg" name="image" autocomplete="off" />
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Nombre del Ponente</label>
                            <input type="text" class="form-control" name="nombrePonente" id="exampleInputEmail1" [(ngModel)]="nombre" aria-describedby="emailHelp">
                        </div>
                        <div class="form-group">
                            <label for="descripcionPonente">Bio</label>
                            <ckeditor [editor]="Editor" id="descripcionPonente" name='ponente' [config]="{ toolbar: [ 'bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote','Heading', 'Link', 'insertTable'] }" [(ngModel)]="data"></ckeditor>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Nombre de la Conferencia</label>
                            <input type="text" class="form-control" name="nombrePonencia" id="exampleInputEmail1" [(ngModel)]="nombrePonencia" aria-describedby="emailHelp">
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Información Adicional</label>
                            <input type="text" class="form-control" name="infPonencia" id="exampleInputEmail1" [(ngModel)]="infoPonencia" aria-describedby="emailHelp">
                        </div>
                        <div class="form-group">
                            <label for="descripcionPonente">Descripcion</label>
                            <ckeditor [editor]="Editor" id="descripcionPonente" name='ponencia' [config]="{ toolbar: [ 'bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote','Heading', 'Link', 'insertTable'] }" [(ngModel)]="contPonencia"></ckeditor>
                        </div>
                        <br>
                        <button [disabled]="guardando" class="btn btn-block btn-primary" (click)="guardarPonencia()">Guardar</button>
                    </form>
                </div>
                <div class="col-md-12">
                    <br>
                </div>
                <div class="col-md-12">
                    <div class="card mb-3" style="width: 100%;">
                        <div class="row no-gutters">
                            <div class="col-md-4">
                                <img [src]="urlImg" class="card-img" alt="...">
                            </div>
                            <div class="col-md-8">
                                <div class="card-body">
                                    <h5 class="card-title">{{nombre}}</h5>
                                    <div class="col-12" [innerHTML]="data"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="card mb-3" style="width: 100%;">
                        <div class="row no-gutters">
                            <div class="col-md-12">
                                <div class="card-body">
                                    <h5 class="card-title">{{nombrePonencia}}</h5>
                                    <div class="col-12" [innerHTML]="contPonencia"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>