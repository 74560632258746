import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ProductService } from '@app/services/product.service';
import { ToastrService } from 'ngx-toastr';
import videojs from 'video.js';

@Component({
  selector: 'app-sala2',
  templateUrl: './sala2.component.html',
  styleUrls: ['./sala2.component.scss']
})

export class Sala2Component implements OnInit, OnDestroy, AfterViewInit {

  email: string;
  password: string;
  logueado: boolean;
  player: videojs.Player;

  constructor(private toast: ToastrService, private prodS: ProductService) {
    this.logueado = false;
  }
  ngAfterViewInit(): void {
    if (this.logueado) {
      this.player = videojs('videojs');
    }
  }

  ngOnInit(): void {
    this.logueado = localStorage.getItem('logueado') ? true : false;
    console.log('LOCAL', localStorage.getItem('logueado'));
  }

  login(form: NgForm) {
    const email = this.email;
    const password = this.password;

    if (form.invalid) {
      this.toast.error('Algunos datos son erroneos.', 'Error')
      return;
    }

    form.reset();
    if (password == 'SUMMIT2020CHILE') {
      localStorage.setItem('logueado', 'admitido');
      this.prodS.crearRegistro(email).then(data => {
        console.log(data);
      });
      this.logueado = true;
      this.toast.success('Bienvenido, disfruta todas las conferencias.', 'Bienvenido');
      setTimeout(()=>{
        this.player = videojs('videojs');
      }, 2000)
    } else {
      this.toast.error('Algunos datos son erroneos.', 'Error');
      this.logueado = false;
    }
  }

  ngOnDestroy() {
    // destroy player
    if (this.player) {
      this.player.dispose();
    }
  }
}
