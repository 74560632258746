<div *ngIf="!logueado" class="container" style="min-height: 100vh; background-color: transparent !important;">
    <div class="jumbotron" style="background: transparent !important;">
        <div class="row">
            <div class="col-md-4 d-flex mx-auto flex-column"></div>
            <div class="col-md-4 d-flex mx-auto flex-column">
                <div class="card" style="width: 100%;">
                    <div class="card-body text-center">
                        <form novalidate #myform="ngForm">
                            <div class="form-group">
                                <label for="email">Email</label>
                                <input class="form-control" id="email" type="email" [(ngModel)]="email" name="email" required>
                            </div>

                            <div class="form-group">
                                <label for="password">Password</label>
                                <input class="form-control" id="password" type="password" [(ngModel)]="password" name="password" required>
                            </div>
                            <div class="d-flex flex-column align-items-center justify-content-around">
                                <button class="btn btn-primary btn-sm btn-block" (click)="login(myform)" [disabled]="myform.invalid && myform.touched">Login</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-md-4 d-flex mx-auto flex-column"></div>
        </div>
    </div>
</div>

<div *ngIf="logueado" class="bajarP" style="min-height: 100vh; background-color: #242423 !important;">
    <div class="row">
        <div class="col-12" style="margin: 0px !important; padding: 0px !important;">
            <video id="videojs" class="video-js vjs-fluid vjs-default-skin vjs-big-play-centered" controls preload="auto">

                <source src="https://live.amelbasoluciones.co:19360/makecreative2/makecreative2.m3u8"
                    type="application/x-mpegURL">
                <p class="vjs-no-js">
                    To view this video please enable JavaScript, and consider upgrading to a web browser that
                    <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
                </p>
            </video>
        </div>
    </div>
</div>