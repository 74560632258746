import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, NgForm, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ProductService } from '@app/services/product.service';
import { ToastrService } from 'ngx-toastr';
import videojs from 'video.js';

@Component({
  selector: 'app-sala1',
  templateUrl: './sala1.component.html',
  styleUrls: ['./sala1.component.scss']
})
export class Sala1Component implements OnInit, OnDestroy, AfterViewInit {

  email: string;
  password: string;
  logueado: boolean;
  player: videojs.Player;
  inicio = false;
  safeSrc: SafeResourceUrl;
  url = 'https://vimeo.com/event/1964139/embed'

  constructor(private toast: ToastrService, private sanitizer: DomSanitizer, private prodS: ProductService, private title: Title) {
    title.setTitle('WEF CHILE 2024 - Sala de Conferencias')
    this.logueado = false;
    this.url = 'https://vimeo.com/event/1964139/embed';
    this.prodS.obtenerURLVideo().subscribe((data:any)=>{
      this.url = data.payload.data().url;
      this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
     });
     this.prodS.obtenerBandera().subscribe((data1:any)=>{
      this.inicio = data1.payload.data().flat;
     });
  }

  ngAfterViewInit(): void {
    if (this.logueado) {
     // this.player = videojs('videojs');
    }
  }

  ngOnInit(): void {
    this.logueado = localStorage.getItem('logueadoWEFCHILE2023') ? true : false;
    console.log('LOCAL', localStorage.getItem('logueadoWEFCHILE2023'));
  }

  login(form: NgForm) {
    const email = this.email;
    const password = this.password;
    const correoV = /^[_a-z0-9-]+(.[_a-z0-9-]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,4})$/;
    console.log('Validacion', correoV.test(this.email.trim().toLowerCase()));

    if (!correoV.test(email)) {
      this.toast.error('Debe introducir un Correo Válido.', 'Error')
      return;
    }

    form.reset();
    localStorage.setItem('logueadoWEFCHILE2023', 'admitido');
    this.prodS.crearRegistro(email).then(data => {
      console.log(data);
    });
    this.logueado = true;
    this.toast.success('Bienvenido, disfruta todas las conferencias.', 'Bienvenido');
    setTimeout(() => {
      this.player = videojs('videojs');
    }, 2000)

  }

  ngOnDestroy() {
    // destroy player
    if (this.player) {
      this.player.dispose();
    }
  }
}
