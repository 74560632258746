import { Component, OnInit } from '@angular/core';
import { Observable } from "rxjs";
import { map, finalize } from "rxjs/operators";
import { AngularFireStorage } from '@angular/fire/storage';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ProductService } from '@app/services/product.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-nuevo-stand',
  templateUrl: './nuevo-stand.component.html',
  styleUrls: ['./nuevo-stand.component.scss']
})
export class NuevoStandComponent implements OnInit {

  selectedFile: File = null;
  fb;
  downloadURL: Observable<string>;
  imagesP = [];
  urlLogo: string;
  urlStand = [];
  nombre: string;
  slogan: string;
  cat: number;
  url: string;
  info: string;

  public editar: boolean;
  public id: string;


  constructor(private dbf: ProductService,
    private storage: AngularFireStorage,
    private router: Router,
    private toast:ToastrService,
    private route: ActivatedRoute) { }


  async onFileSelected(event) {
    var n = Date.now();
    const file = event.target.files[0];
    const filePath = `Logos/${n}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(`Logos/${n}`, file);
    await task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.downloadURL = fileRef.getDownloadURL();
          this.downloadURL.subscribe(url => {
            if (url) {
              this.urlLogo = url;
            }
            console.log("URL", this.urlLogo);
          });
        })
      )
      .subscribe(url => {
        if (url) {
          console.log('ESTE', url);
        }
      });
  }

  async onFileSelected1(event) {
    try {
      //this.imagesP = [];
      console.log(event.target.files.length);

      const flat = event.target.files.length > 3 ? 3 : event.target.files.length - 1;

      for (let i = 0; i < event.target.files.length; i++) {
        if (i > 3) {
          break;
        }
        var n = Date.now();
        const file = event.target.files[i];
        const filePath = `Productos/${n}`;
        const fileRef = this.storage.ref(filePath);
        const task = this.storage.upload(`Productos/${n}`, file);
        await task
          .snapshotChanges()
          .pipe(
            finalize(async () => {
              this.downloadURL = fileRef.getDownloadURL();
              await this.downloadURL.subscribe(url => {
                if (url) {
                  this.fb = url;
                }
                this.imagesP.push(url);
              });
            })
          )
          .subscribe(url => {
            if (url) {
              console.log('');
            }
          });
      }
    } catch (e) {
      console.log(e);
    }
  }


  async onFileSelected2(event) {
    this.urlStand = [];
    try {
      //this.imagesP = [];
      console.log(event.target.files.length);

      const flat = event.target.files.length > 2 ? 2 : event.target.files.length - 1;

      for (let i = 0; i < event.target.files.length; i++) {
        if (i > 2) {
          break;
        }
        var n = Date.now();
        const file = event.target.files[i];
        const filePath = `Stands/${n}`;
        const fileRef = this.storage.ref(filePath);
        const task = this.storage.upload(`Stands/${n}`, file);
        await task
          .snapshotChanges()
          .pipe(
            finalize(async () => {
              this.downloadURL = fileRef.getDownloadURL();
              await this.downloadURL.subscribe(url => {
                if (url) {
                  this.fb = url;
                }
                this.urlStand.push(url);
                console.log(url);
              });
            })
          )
          .subscribe(url => {
            if (url) {
              console.log(url);
            }
          });
      }
    } catch (e) {
      console.log(e);
    }
  }

  deleteImg(index: number) {
    let httpsReference = this.storage.storage.refFromURL(this.imagesP[index]);
    httpsReference.delete().then(dataD => {
      console.log(dataD);
      this.imagesP.splice(index, 1);
    })
  }

  guardarStand() {
    var urlsProd = '';
    this.imagesP.forEach(dataP => {
      urlsProd = urlsProd + dataP + ';';
    });
    var urlsStands = '';
    this.urlStand.forEach(dataS => {
      urlsStands = urlsStands + dataS + ';';
    });

    console.log({ nombreStand: this.nombre, slogan: this.slogan, urlLogo: this.urlLogo, urlStand: this.urlStand, urlProd: urlsProd });
    if (this.editar) {
      console.log('Editar');
      this.dbf.editarStand(this.id, { nombreStand: this.nombre, categoria:this.cat, slogan: this.slogan, urlLogo: this.urlLogo, urlStand: urlsStands, urlProd: urlsProd, url: this.url }).then(data => {
        console.log('Editar', data);
        this.toast.success('El stand de '+this.nombre+' fue editado', 'Editado');
        this.router.navigateByUrl('/admin');
      });
    } else {
      console.log('Crear');
      this.dbf.crearStand({ nombreStand: this.nombre, categoria:this.cat, slogan: this.slogan, urlLogo: this.urlLogo, urlStand: urlsStands, urlProd: urlsProd, url: this.url }).then(data => {
        console.log('Crear', data);
        this.toast.success('El stand de '+this.nombre+' fue creado', 'Creado');
        this.router.navigateByUrl('/admin');
      });
    }
  }

  ngOnInit() {
    this.route.paramMap
      .pipe(
        map((param: ParamMap) => {
          // @ts-ignore
          return { id: param.params.id };
        })
      ).subscribe(dataPar => {
        console.log('id', dataPar);
        this.id = dataPar.id;
        if (dataPar.id !== 'new') {
          this.editar = true;
          this.dbf.obtenerStand(dataPar.id).then(dataP => {
            console.log(dataP.data());
            this.imagesP = dataP.data().urlProd.split(';');
            this.urlLogo = dataP.data().urlLogo;
            this.urlStand = dataP.data().urlStand.split(';');;
            this.nombre = dataP.data().nombreStand;
            this.slogan = dataP.data().slogan;
            this.cat = dataP.data().categoria;
            this.url = dataP.data().url;
            this.info = dataP.data().info;
          });
        } else {
          this.editar = false;
        }

      });
  }

}
