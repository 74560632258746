<div *ngIf="!inicio" class="container" style="min-height: 100vh; background-color: #e1e1e1 !important; max-width: 100% !important; padding: 0px !important; margin: 0px !important;">
    <img src="/assets/imagen/Muy_Pronto.png" style="width: 100%;" alt="Muy Pronto">
</div>
<div *ngIf="inicio" class="container" style="min-height: 100vh; background-color: #e1e1e1 !important; width: 100% !important; margin: 0px !important; padding: 0px !important; max-width: 100% !important;">
    <div *ngIf="!logueado" class="container" style="min-height: 100vh; background-color: transparent !important; background-image: url('/assets/imagen/FondoWEF.webp'); background-repeat: no-repeat; background-attachment: fixed; background-size: cover; width: 100% !important; margin: 0px !important; padding: 0px !important; max-width: 100% !important;">
        <div class="jumbotron" style="background: transparent !important;">
            <div class="row">
                <div class="col-md-4 d-flex mx-auto flex-column"></div>
                <div class="col-md-4 d-flex mx-auto flex-column">
                    <div class="card" style="width: 100%;">
                        <div class="card-body text-center">
                            <form novalidate #myform="ngForm">
                                <div class="form-group">
                                    <label for="email">Email</label>
                                    <input class="form-control" id="email" type="email" [(ngModel)]="email" name="email" required>
                                </div>

                                <!--<div class="form-group">
                                <label for="password">Password</label>
                                <input class="form-control" id="password" type="password" [(ngModel)]="password" name="password" required>
                            </div>-->
                                <div class="d-flex flex-column align-items-center justify-content-around">
                                    <button class="btn btn-primary btn-sm btn-block" (click)="login(myform)" [disabled]="myform.invalid && myform.touched">Entrar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 d-flex mx-auto flex-column"></div>
            </div>
        </div>
    </div>
    <!-- Servidor Felipe
<div *ngIf="logueado" class="bajarP" style="min-height: 100vh; background-color: #242423 !important">
    <div class="row">
        <div class="col-12" style="margin: 0px !important; padding: 0px !important;">
            <video id="videojs" class="video-js vjs-fluid vjs-default-skin vjs-big-play-centered" controls preload="auto">

                <source src="https://servers.amelbasoluciones.co:19360/makecreative/makecreative.m3u8"
                    type="application/x-mpegURL">
                <p class="vjs-no-js">
                    To view this video please enable JavaScript, and consider upgrading to a web browser that
                    <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
                </p>
            </video>
        </div>
    </div>
</div>
-->
    <!--Servidor YouTube
<div *ngIf="logueado" class="bajarP" style="min-height: 100vh; background-color: #242423 !important">
    <div class="row">
        <div class="col-12" style="margin: 0px !important; padding: 0px !important;">
            <div class="container1">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/UVpMB2RpkBg?controls=0&rel=0&showinfo=1&autoplay=1&modestbranding=1&iv_load_policy=3" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </div>
        </div>
    </div>
</div>
-->
    <!--Servidor Felipe 2-->
    <div *ngIf="logueado" style="padding:56.25% 0 0 0;position:relative; width: 100% !important; max-width: 100% !important;">
        <iframe [src]="safeSrc" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe>
    </div>

</div>
