import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ProductService } from '@app/services/product.service';
import { Observable } from 'rxjs';
import { map, finalize } from "rxjs/operators";

@Component({
  selector: 'app-detalle-stand',
  templateUrl: './detalle-stand.component.html',
  styleUrls: ['./detalle-stand.component.scss']
})
export class DetalleStandComponent implements OnInit {

  public id: string;
  public logos = [];
  public productos = [];
  public url: string;
  showNavigationArrows = true;
  showNavigationIndicators = false;


  constructor(private dbf: ProductService, private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.paramMap
      .pipe(
        map((param: ParamMap) => {
          // @ts-ignore
          return { id: param.params.id };
        })
      ).subscribe(dataPar => {
        this.id = dataPar.id;
        this.dbf.obtenerStand(dataPar.id).then(dataP => {
          console.log('DATA',dataP.data());
          dataP.data().urlStand.split(';').forEach(element => {
            if(element!==""){
              this.logos.push(element);
            }
          });
          this.productos = dataP.data().urlProd.split(';');
          this.url = dataP.data().url;
          console.log('productos', this.productos);
        });
      });
  }

}
