import { Component, OnInit } from '@angular/core';
import { ProductService } from '@app/services/product.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-home-admin',
  templateUrl: './home-admin.component.html',
  styleUrls: ['./home-admin.component.scss']
})
export class HomeAdminComponent implements OnInit {

  public stands: any;
  public ponencias: any;
  public activo: number;
  public Editor = ClassicEditor;
  public cronograma: string;
  public guardando = false;

  constructor(private dbf: ProductService) {

  }

  ngOnInit() {
    this.activo = 1;
    this.dbf.obtenerTodosStands().subscribe((standsSnapshot) => {
      this.stands = [];
      standsSnapshot.forEach((catData: any) => {
        this.stands.push({
          id: catData.payload.doc.id,
          ...catData.payload.doc.data()
        });
      });
      console.log(this.stands);
    });
    this.dbf.obtenerTodosPonencias().subscribe((ponenciasSnapshot) => {
      this.ponencias = [];
      ponenciasSnapshot.forEach((catData: any) => {
        this.ponencias.push({
          id: catData.payload.doc.id,
          ...catData.payload.doc.data()
        });
      });
      console.log(this.ponencias);
    });
    this.dbf.obtenerCronograma().then(data=>{
      this.cronograma=data.exists?data.data().cronograma:'';
    });
  }

  eliminarStand(id: string) {
    this.dbf.borrarStand(id).then(data => {
      console.log(data);
    });
  }

  eliminarPonencia(id: string) {
    this.dbf.borrarPonencia(id).then(data => {
      console.log(data);
    });
  }

  cambiarTabs(id: number) {
    this.activo = id;
  }

  guardar() {
    this.guardando = false;
    this.dbf.editarCronograma({cronograma:this.cronograma}).then(data=>{
      console.log(data);
      this.guardando = true;
    });
  }

}
