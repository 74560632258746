import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductService } from './services/product.service';
import { UserService } from './services/user.service';
import ImageMap from "image-map";

declare let $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'stand';
  public ponentes = false;
  public ponentesData = [];
  public seleccionado = 0;
  public logos = [
    { img: 'assets/imagen/1.png', url: '' },
    { img: 'assets/imagen/2.png', url: '' },
    { img: 'assets/imagen/3.png', url: '' },
    { img: 'assets/imagen/4.png', url: '' },
    { img: 'assets/imagen/5.png', url: '' },
    { img: 'assets/imagen/6.png', url: '' },
    { img: 'assets/imagen/7.png', url: '' },
    { img: 'assets/imagen/8.png', url: '' },
    { img: 'assets/imagen/9.png', url: '' },
    { img: 'assets/imagen/10.png', url: '' },
    { img: 'assets/imagen/11.png', url: '' },
    { img: 'assets/imagen/12.png', url: '' },
    { img: 'assets/imagen/13.png', url: '' },
    { img: 'assets/imagen/14.png', url: '' },
    { img: 'assets/imagen/15.png', url: '' },
    { img: 'assets/imagen/16.png', url: '' },
    { img: 'assets/imagen/17.png', url: '' },
    { img: 'assets/imagen/18.png', url: '' },
    { img: 'assets/imagen/19.png', url: '' },
    { img: 'assets/imagen/20.png', url: '' },
    { img: 'assets/imagen/21.png', url: '' },
    { img: 'assets/imagen/22.png', url: '' },
    { img: 'assets/imagen/23.png', url: '' },
    { img: 'assets/imagen/24.png', url: '' },
    { img: 'assets/imagen/25.png', url: '' },
    { img: 'assets/imagen/26.png', url: '' },
    { img: 'assets/imagen/27.png', url: '' },
    { img: 'assets/imagen/28.png', url: '' },
    { img: 'assets/imagen/29.png', url: '' },
    { img: 'assets/imagen/30.png', url: '' },
    { img: 'assets/imagen/31.png', url: '' },
    { img: 'assets/imagen/32.png', url: '' },
    { img: 'assets/imagen/33.png', url: '' },
    { img: 'assets/imagen/34.png', url: '' },
    { img: 'assets/imagen/35.png', url: '' },
    { img: 'assets/imagen/36.png', url: '' },
    { img: 'assets/imagen/37.png', url: '' },
    { img: 'assets/imagen/38.png', url: '' },
    { img: 'assets/imagen/39.png', url: '' }
  ]

  constructor(private router: Router, private dbf: ProductService, public userService: UserService) {
    this.ponentes = false;
  }

  ngOnInit() {
    this.dbf.obtenerTodosPonentes().then((ponenciasSnapshot) => {
      ponenciasSnapshot.forEach((catData: any) => {
        this.ponentesData.push({
          id: catData.id,
          ...catData.data()
        });
      });
    });
    console.log('PONENTES', this.ponentesData);
    this.router.events.subscribe(dataUrl=>{
      console.log(this.router.url);
      this.ponentes = this.router.url=='/auditorio';
    });
  }

  ngAfterViewInit(): void {
    $('.js-colorlib-nav-toggle').on('click', function (event) {
      event.preventDefault();
      var $this = $(this);

      if ($('body').hasClass('offcanvas')) {
        $this.removeClass('active');
        $('body').removeClass('offcanvas');
      } else {
        $this.addClass('active');
        $('body').addClass('offcanvas');
      }
    });
  }

  irDetalle() {
    console.log(this.ponentesData[this.seleccionado]);
    this.router.navigateByUrl('detallePonencia/' + this.ponentesData[this.seleccionado].id)
  }

  onIndexChange(index: number) {
    this.seleccionado = index;
  }

}

