<div class="card" style="width: 100%;">
    <div class="card-body">

        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="card mb-3" style="width: 100%;">
                        <div class="row no-gutters">
                            <div class="col-md-4">
                                <img [src]="urlImg" class="card-img" alt="...">
                            </div>
                            <div class="col-md-8">
                                <div class="card-body">
                                    <h5 class="card-title">{{nombre}}</h5>
                                    <div class="col-12" [innerHTML]="data"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="card mb-3" style="width: 100%;">
                        <div class="row no-gutters">
                            <div class="col-md-12">
                                <div class="card-body">
                                    <h5 class="card-title">{{nombrePonencia}}</h5>
                                    <div class="col-12" [innerHTML]="contPonencia"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>