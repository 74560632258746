<div id="colorlib-page">
    <a href="#" class="js-colorlib-nav-toggle colorlib-nav-toggle"><i></i></a>
    <aside id="colorlib-aside" role="complementary" class="js-fullheight text-center">
        <div id="colorlib-logo">
            <img src="assets/imagen/Logo_wef1.png" style="width: 92%;" alt="Logo Evento">
        </div>

        <carousel class="pruebaSlider" *ngIf="!userService.userAdmin" [overflowCellsLimit]="[1]" [height]="[350]" [loop]="[true]" [autoplay]="[true]" [transitionDuration]="[800]" [transitionTimingFunction]="['ease-in-out']" [autoplayInterval]="[3000]" [cellsToShow]="[1]"
            [arrows]="[false]">
            <div class="carousel-cell" *ngFor="let logo of logos">
                <div class="card" style="width: 100%; background-color: transparent;">
                    <a [href]="logo.url" target="_blank">
                        <img [src]="logo.img" style="width: 100%;" alt="">
                    </a>
                </div>
            </div>
        </carousel>

        <div *ngIf="!userService.userAdmin" class="colorlib-footer" style="z-index: 500;">
            <a [routerLink]="['/sala1']" style="color: #760087; background-color: #ffffff;position:relativa;z-index: 100;" type="button" class="btn my-2 btn-block btn-secondary" placement="top" ngbTooltip="Ingresa a las conferencias Aquí" title="Ingresa a las conferencias Aquí">
                <strong>Ingresa Aquí</strong>
            </a>
            <!--
            <div class="d-flex justify-content-center mb-2">
                <ul class="d-flex align-items-center">
                    <li class="d-flex align-items-center jusitfy-content-center" placement="top" ngbTooltip="Entrar a la Sala 1" title="Sala 1"><a href="/sala1"><i class="icon-university"></i>1</a></li>
                    <li class="d-flex align-items-center jusitfy-content-center" placement="top" ngbTooltip="Entrar a la Sala 2" title="Sala 2"><a href="/sala2"><i class="icon-university"></i>2</a></li>

                </ul>
            </div>-->
            <div class="d-flex justify-content-center">
                <ul class="d-flex align-items-center">
                    <li class="d-flex align-items-center jusitfy-content-center" placement="top" ngbTooltip="Entrada" title="Entrada"><a [routerLink]="['/']"><i class="icon-home"></i></a></li>
                    <li class="d-flex align-items-center jusitfy-content-center" placement="top" ngbTooltip="Lobby" title="Lobby"><a [routerLink]="['/lobby']"><i class="icon-female"></i></a>
                    </li>
                    <!--
                    <li class="d-flex align-items-center jusitfy-content-center" placement="top" ngbTooltip="Feria Emprendedora" title="ExpoFeria"><a href="/feria"><i
                                class="icon-shopping_cart"></i></a>
                    </li>-->
                    <li class="d-flex align-items-center jusitfy-content-center" placement="left" ngbTooltip="Auditorio" title="Auditorio"><a [routerLink]="['/auditorio']"><i class="icon-university"></i></a>
                    </li>
                    <li class="d-flex align-items-center jusitfy-content-center" placement="left" ngbTooltip="Programa del WEF" title="Programa"><a [routerLink]="['/conferencias']"><i class="icon-book"></i></a>
                    </li>
                </ul>
            </div>
        </div>
        <div *ngIf="userService.userAdmin" class="colorlib-footer" style="z-index: 500;">
            <button type="button" (click)="userService.logout()" class="btn my-2 btn-block btn-secondary" placement="top" ngbTooltip="Cerrar Sesion" title="Cerrar Sesion">
                <strong>Logout</strong>
            </button>
            <a class="btn my-2 btn-block btn-secondary" [routerLink]="['/admin']"><i class="icon-home"></i></a>
        </div>
    </aside>
    <!-- END COLORLIB-ASIDE -->
    <div id="colorlib-main">
        <section class="ftco-section-2">
            <router-outlet></router-outlet>
        </section>
        <footer class="ftco-footer ftco-bg-dark ftco-section">
            <div class="container">
                <div class="row">
                    <div class="col-md-4"></div>
                    <div class="col-md-4"></div>
                    <div class="col-md-4 text-black text-center">
                        <div class="row">
                            <div class="col-md-12">
                                Desarrollado por <a href="https://moqapiweb.com/" target="_blank">NetsWeb</a>
                            </div>
                            <div class="col-md-12">
                                <a href="https://moqapiweb.com/" target="_blank">
                                    <img style="width: 80%; margin: auto;" src="assets/imagen/LogoNetsWeb.png" alt="Logo NetsWeb">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
    <!-- END COLORLIB-MAIN -->
</div>
<!-- END COLORLIB-PAGE -->