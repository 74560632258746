import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import ImageMap from "image-map";

@Component({
  selector: 'app-feria',
  templateUrl: './feria.component.html',
  styleUrls: ['./feria.component.scss']
})
export class FeriaComponent implements OnInit {
  constructor() {

  }

  ngOnInit() {
    ImageMap('img[usemap]');
  }

}
