import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductService } from '@app/services/product.service';

@Component({
  selector: 'app-list-stands',
  templateUrl: './list-stands.component.html',
  styleUrls: ['./list-stands.component.scss']
})
export class ListStandsComponent implements OnInit {

  public standsData = [];
  public standsAux = [];
  public activo = 0;

  constructor(private router: Router, private dbf: ProductService) { }

  ngOnInit() {
    this.dbf.obtenerStandTodos().then((ponenciasSnapshot) => {
      ponenciasSnapshot.forEach((catData: any) => {
        this.standsData.push({
          id: catData.id,
          ...catData.data()
        });
        this.standsAux = this.standsData;
      });
    });
    console.log('PONENTES', this.standsData);
  }

  cambiarTabs(opc: number) {
    this.activo = opc;
    if(opc==0){
      this.standsData = this.standsAux;
    } else{
      this.filtrar(opc);
    }
  }

  filtrar(opc) {
    this.standsData = [];
    this.standsAux.forEach(data => {
      if(data.categoria==opc){
        this.standsData.push(data);
      }
    });
  }

}
