<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Bienvenidos </h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body text-center">
        <video width="80%" controls autoplay controlsList="nodownload">
      <source
        src="https://firebasestorage.googleapis.com/v0/b/nets-web.appspot.com/o/WEF2024%2FIntro%20WEF%20-%20V5.mp4?alt=media&token=1812bf2e-1385-419d-a197-2e628f5c39bf"
        type="video/mp4">
      Your browser does not support the video tag.
    </video>

    </div>
</ng-template>

<figure #visorP class='visor'>
    <a [routerLink]="['/lobby']">
        <img style="height: 100vh;" src="https://firebasestorage.googleapis.com/v0/b/nets-web.appspot.com/o/WEF2024%2Fentradagif.gif?alt=media&token=912ca511-9ade-453b-905b-9b478807d036">
    </a>
</figure>
