import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private firestore: AngularFirestore) {
    console.log('AsistentesWef2024',this.firestore.collection('asistentesWef2024').get().subscribe(data=>{
      var cont = 0;
      data.forEach(dataAsis=>{
        cont++;
        console.log(cont+'.-'+dataAsis.data().email);
      })
    }));
   }

  public crearPonencia(dataPonencia) {
    return this.firestore.collection('ponenciasWef2024').add(dataPonencia);
  }

  public editarPonencia(id:string, dataPonencia) {
    return this.firestore.collection('ponenciasWef2024').doc(id).update(dataPonencia);
  }

  public crearStand(dataStand) {
    return this.firestore.collection('stands').add(dataStand);
  }

  public editarStand(id:string, dataStand) {
    return this.firestore.collection('stands').doc(id).update(dataStand);
  }

  public borrarPonencia(id: string) {
    return this.firestore.collection('ponenciasWef2024').doc(id).delete();
  }

  public borrarStand(id: string) {
    return this.firestore.collection('stands').doc(id).delete();
  }

  public obtenerStand(id: string) {
    return this.firestore.collection('stands').doc(id).ref.get();
  }

  public obtenerPonencia(id: string) {
    return this.firestore.collection('ponenciasWef2024').doc(id).ref.get();
  }

  public obtenerTodosStands() {
    return this.firestore.collection('stands').snapshotChanges();
  }

  public editarCronograma(data) {
    return this.firestore.collection('cronograma').doc('unico').set(data);
  }

  public obtenerCronograma() {
    return this.firestore.collection('cronograma').doc('unico').ref.get();
  }

  public crearRegistro(email) {
    return this.firestore.collection('asistentesWef2024').add({email});
  }

  public obtenerURLVideo() {
    return this.firestore.collection('urlEvents').doc('urlWEF2024').snapshotChanges();
  }

  public obtenerBandera() {
    return this.firestore.collection('urlEvents').doc('banderaWEF2024').snapshotChanges();
  }

  public obtenerTodosPonencias() {
    return this.firestore.collection('ponenciasWef2024').snapshotChanges();
  }

  public obtenerTodosPonentes() {
    return this.firestore.collection('ponenciasWef2024').ref.orderBy("infoPonencia", "desc").get();
  }

  public obtenerStandTodos() {
    this.firestore.collection('asistentesWef2024').ref.get().then(data=>{
      console.log(data);
      data.forEach(user=>{
        console.log("Email : "+user.data().email);
      });
    });
    return this.firestore.collection('stands').ref.get();
  }
}

