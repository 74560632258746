<div class="card" style="width: 100%; min-height: 100vh;">
    <div class="card-body text-center">
        <h5 class="card-title">Stands</h5>
        <ul class="nav nav-tabs mx-auto">
            <!--
            <li (click)="cambiarTabs(0)" class="nav-item">
                <a class="nav-link" [class.active]="activo==0" href="javascript:void(0);">TODOS</a>
            </li>
            <li (click)="cambiarTabs(2)" class="nav-item">
                <a class="nav-link" [class.active]="activo==2" href="javascript:void(0);">DIGITAL Y SERVICIOS</a>
            </li>
            <li (click)="cambiarTabs(1)" class="nav-item">
                <a class="nav-link" [class.active]="activo==1" href="javascript:void(0);">ALIMENTOS, BEBIDAS Y GOURMET</a>
            </li>
            <li (click)="cambiarTabs(4)" class="nav-item">
                <a class="nav-link" [class.active]="activo==4" href="javascript:void(0);">MODA , ACCESORIOS Y MATERIA PRIMA</a>
            </li>
            <li (click)="cambiarTabs(3)" class="nav-item">
                <a class="nav-link" [class.active]="activo==3" href="javascript:void(0);">SERVICIOS PROFESIONALES</a>
            </li>-->
        </ul>
        <div class="container">
            <div class="row">
                <div class="col-md-4" *ngFor="let stand of standsData">
                    <div class="card my-2" style="width: 100%;">
                        <a [routerLink]="['/detalleStand/'+stand.id]">
                            <img [src]="stand.urlLogo" class="card-img-top" [alt]="stand.nombrePonente">
                        </a>
                        <div class="card-body">
                            <h5 class="card-title">{{stand.nombreStand}}</h5>
                            <p class="card-text">
                                {{stand.slogan.length>58?(stand.slogan.substring(0,58)+'...'):stand.slogan}}
                            </p>
                        </div>
                        <div class="card-footer">
                            Stand
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>