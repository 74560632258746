import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clp'
})
export class ClpPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    const valor = "" + value;
    var cont = 0;
    var newValor = ""
    for (let index = valor.length - 1; index >= 0; index--) {
      const element = valor[index];
      newValor = element + newValor;
      cont++;
      if (cont == 3) {
        newValor = '.' + newValor;
        cont = 0;
      }
    }
    if (newValor[0] === '.') {
      newValor = newValor.slice(1, newValor.length);
    }
    return '$' + newValor;
  }

}
