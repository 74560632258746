import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import * as firebase from 'firebase';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  auth1 = false;
  authState$ = new BehaviorSubject<boolean>(this.auth1);
  userData$ = new BehaviorSubject<object>(null);
  userAdmin: boolean;

  constructor(private router: Router,
    private toast: ToastrService,
    public afAuth: AngularFireAuth,
    private firestore: AngularFirestore) {
    this.userAdmin = false;
    this.afAuth.onAuthStateChanged(authUser => {
      authUser ? localStorage.setItem('authUserAdmin', JSON.stringify(authUser)) : localStorage.removeItem('authUserAdmin');
      console.log('CHANGE', authUser, firebase.auth().currentUser);
      if (firebase.auth().currentUser) {
        this.auth1 = true;
        this.authState$.next(this.auth1);
        this.isAdmin(firebase.auth().currentUser.uid);
        this.router.navigateByUrl('admin');
      } else {
        this.auth1 = false;
        this.authState$.next(this.auth1);
      }
    });
  }
 //  Login User with Email and Password
  loginUser(email: string, password: string) {
    this.afAuth.signInWithEmailAndPassword(email, password).then(dataUser => {
      console.log(dataUser.user.uid);
      this.firestore.collection('users').doc(dataUser.user.uid).get().subscribe(data => {
        console.log(data.data());
        this.auth1 = true;
        this.authState$.next(this.auth1);
        this.userData$.next(data.data());
        this.isAdmin(dataUser.user.uid);
        this.router.navigateByUrl('');
      })
    })
      .catch(error => {
        this.toast.error('Datos Incorrectos', 'Error', {
          timeOut: 3000,
          progressBar: true,
          progressAnimation: 'increasing',
          positionClass: 'toast-top-right'
        });
        console.log('ERRRO', error);
        this.afAuth.signOut();
        this.auth1 = false;
        this.authState$.next(this.auth1);
      });
  }


  logout() {
    //this.afAuth.signOut();
    this.afAuth.signOut();
    this.auth1 = false;
    this.authState$.next(this.auth1);
    console.log('deslogueo');
  }

  isAdmin(uid: string) {
    this.firestore.collection('usersAdmin').doc(uid).get().subscribe(data => {
      this.userAdmin = data.exists;
      console.log("isAdmin?", this.userAdmin);
      if (!this.userAdmin) {
        this.logout();
        console.log('deslogueo');
      }
      this.router.navigateByUrl('');
    })
  }

}
