import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ProductService } from '@app/services/product.service';

@Component({
  selector: 'app-list-ponencias',
  templateUrl: './list-ponencias.component.html',
  styleUrls: ['./list-ponencias.component.scss']
})
export class ListPonenciasComponent implements OnInit {

  public ponentesData = [];
  public activo = 1;
  public cronograma: string;
  public paneles = [];
  public presencial = [];
  public online = [];

  constructor(private router: Router, private dbf: ProductService, private title: Title) {
    title.setTitle('WEF CHILE 2024 - Conferencistas');
  }

  ngOnInit() {
    this.dbf.obtenerTodosPonentes().then((ponenciasSnapshot) => {
      ponenciasSnapshot.forEach((catData: any) => {
        if (catData.data().infoPonencia === 'presencial') {
          this.presencial.push({
            id: catData.id,
            ...catData.data()
          });
        }
        if (catData.data().infoPonencia === 'online') {
          this.online.push({
            id: catData.id,
            ...catData.data()
          });
        }
        if (catData.data().infoPonencia === 'panel') {
          this.paneles.push({
            id: catData.id,
            ...catData.data()
          });
        }
      });
    });
    console.log('PONENTES', this.ponentesData);
    this.dbf.obtenerCronograma().then(data => {
      this.cronograma = data.exists ? data.data().cronograma : '';
    });
  }

  irA(id: string) {
    this.router.navigateByUrl('detallePonencia/' + id);
  }

  cambiarTabs(opc: number) {
    this.activo = opc;
  }

}
